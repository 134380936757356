let apiUrl = process.env.VUE_APP_API_URL;


class FolderPlanApi {

    constructor() {
        this.token = JSON.parse(window.localStorage.getItem('token'));
    }


    getList(params) {
        var url = apiUrl + 'folders?' + 'limit=' + params.limit + '&page=' + params.page;
        
        return api.call('get', url)
          .then(({ data }) => {
            return data;
        });
    }


    get() {
        var url = apiUrl + 'folders?limit=10&page=1';

        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    getById(id) {
      var url = apiUrl + 'folders/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }

    create(data) {
        var url = apiUrl + 'folders';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    update(data) {
        var url = apiUrl + 'folders/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }

    updateScale(data) {
        var url = apiUrl + 'folders/UpdateScale/';
        return api.call('put', url + data.id, data)
            .then(({ data }) => {
                return data
            });
    }

    delete(id) {
        var url = apiUrl + 'folders/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default FolderPlanApi;
